import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import Article from "components/Article";
import LinkButton from "components/buttons/LinkButton";
import ShareButton from "components/buttons/ShareButton";
import Layout from "components/Layout";
import { graphql, navigate, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import styled from "styled-components";

interface DataProps {
    mdx: {
        frontmatter: {
            title: string;
            description: string;
            date: string;
            author: string;
        };
        body: string;
    };
    site: {
        siteMetadata: {
            siteUrl: string;
        };
    };
}

interface BlogLocationState {
    /** true if we're coming from the blog post list page */
    fromBlogIndex?: boolean;
}

const BlogPost: React.FC<
    PageProps<DataProps, { slug: string }, BlogLocationState>
> = ({ path, location, data, pageContext }) => {
    const { mdx, site } = data;
    const { frontmatter, body } = mdx;
    const { title, description, date, author } = frontmatter;
    const siteUrl = site.siteMetadata.siteUrl;
    const { slug } = pageContext;

    const layoutProps = {
        path,
        title,
        description,
        slug,
    };

    const backButtonProps = {
        didNavigateFromBlogIndex: location.state?.fromBlogIndex,
    };

    const url = [siteUrl, path].join("");

    const shareOptions = {
        title,
        text: description,
        url,
    };

    return (
        <Layout {...layoutProps}>
            <Article>
                <Article.Header>
                    <div style={{ marginBottom: "-1rem" }}>
                        <Article.Heading>{title}</Article.Heading>
                        <div className="vstack text-end text-md-center text-muted gap-md-1 pt-2">
                            <div>
                                <small>{date}</small>
                            </div>
                            <div>{author}</div>
                        </div>
                    </div>
                </Article.Header>

                <Article.BodyM>
                    <MDXRenderer>{body}</MDXRenderer>
                    <ShareButtonContainer>
                        <ShareButton {...{ shareOptions }} />
                    </ShareButtonContainer>
                </Article.BodyM>

                <Article.Footer>
                    <div className="d-flex justify-content-center mt-4">
                        <BackButton {...backButtonProps} />
                    </div>
                </Article.Footer>
            </Article>
        </Layout>
    );
};

export default BlogPost;

export const query = graphql`
    query ($id: String!, $previousId: String, $nextId: String) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                description
                date(formatString: "MMMM DD, YYYY")
                author
            }
            body
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
        previous: mdx(id: { eq: $previousId }) {
            fields {
                slug
                sourceInstanceName
            }
            frontmatter {
                title
            }
        }
        next: mdx(id: { eq: $nextId }) {
            fields {
                slug
                sourceInstanceName
            }
            frontmatter {
                title
            }
        }
    }
`;

const ShareButtonContainer = styled.div`
    margin-top: 1.8rem;
    /* The article section already has a 1rem bottom padding */
    margin-bottom: 0.8rem;
`;

interface BackButtonProps {
    /** true if we're coming from the blog post list page */
    didNavigateFromBlogIndex?: boolean;
}

const BackButton: React.FC<BackButtonProps> = ({
    didNavigateFromBlogIndex,
}) => {
    function handleClick() {
        // If we're coming here from the list of blog posts, use the history API
        // to go back one page. This allows the scroll location to be preserved.
        if (didNavigateFromBlogIndex) {
            navigate(-1);
        } else {
            navigate("/blog/");
        }
    }

    return (
        <LinkButton.Secondary onClick={handleClick}>
            {/* Add extra trailing padding for better visual centering */}
            <div className="hstack gap-2 pe-2">
                <ArrowLeftIcon width={18} />
                Back to all posts
            </div>
        </LinkButton.Secondary>
    );
};
